import React from "react"

export default function HeaderTitle({className, title, sub}) {

  const textClamp = " text-[clamp(32px,3.125vw,40px)]"
  const cl = className ? className + textClamp : textClamp

  return (
    <>
      <h2 className="flex flex-col mt-5">
        <span className={cl}>
          {title}
        </span>
        <span className="text-xs inline-block font-sans text-omcGray">
          {sub}
        </span>
      </h2>
    </>
  )
}
