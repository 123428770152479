import React from "react"
import BasicBadge from "../Badge/BasicBadge"
import SmallBadge from "../Badge/SmallBadge"
import { BsYoutube } from "react-icons/bs"
import { Link } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"

export default function Card({ title, img, time, tags, hasVideo, link }) {
  const slicedTags = tags.slice(0,2)
  return (
    <Link
      to={`/recipe/` + link}
      className="card-wrapper overflow-hidden rounded-lg bg-white custom-shadow-md"
    >
      <ImageFromNewt
        src={img}
        alt={title}
        className={"aspect-square object-cover card-image"}
      />
      <div className="card-meta-content">
        {hasVideo ? <BsYoutube size={28} /> : ""}
        <div className="mb-2 pl-4">
          <BasicBadge text={time} mobileFontSize={"sm"} />
        </div>
        <div className="card-meta-tags">
          {slicedTags.map((tag, i) => {
            return <SmallBadge key={i} text={tag} />
          })}
        </div>
      </div>

      <div className="py-4">
        <h3 className="text-center px-4 leading-normal text-sm">{title}</h3>
      </div>
    </Link>
  )
}
