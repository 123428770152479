import React from "react"

export default function MakerTitle({ text,className }) {
  return (
    <div className="flex mb-3">
      <h3 className={`font-alphabet text-3xl markerTitle inline-block m-auto md:ml-0 ${className}`}>
        {text}
      </h3>
    </div>
  )
}
