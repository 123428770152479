import React from "react"
import GetRelatedLifestyle from "./GetRelatedLifestyle"
import GetRelatedSpecialFeature from "./GetRelatedSpecialFeature"

export default function ShowMustRead(relatedPostField) {
  const { relatedLifestyle, specialFeature } = relatedPostField.relatedPostField

  const getLspost = () => {
    if (relatedLifestyle.length > 0) {
      const title = relatedLifestyle.map(data => {
        return data.title
      })
      return <GetRelatedLifestyle title={title[0]} />
    }
  }

  const getSpeciaFeaturePost = () => {
    if (specialFeature != null) {
      return (
        <GetRelatedSpecialFeature
          specialFeatureName={specialFeature.specialFeatureName}
        />
      )
    }
  }

  if (relatedLifestyle.length > 0 || specialFeature != null) {
    return (
      <section>
        <div className="bg-omcGold my-12">
          <h2 className="must-read-title py-4">
            <span className="max-w-screen-md mx-auto block font-alphabet text-white text-xl text-center z-10 relative">
              Must-Read
            </span>
          </h2>
          {getSpeciaFeaturePost()}
          {getLspost()}
        </div>
      </section>
    )
  }
}
