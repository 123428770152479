import React from "react"

export default function VideoDisplay({ video }) {
  if (video !== "") {
    const url = video
    const videoId = url.replace("https://youtu.be/", "")
    return (
      <>
        <div className="flex flex-col mb-16 overflow-hidden rounded-lg custom-shadow-md w-full">
          <div className="aspect-video w-full">
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              title="ohmycottie recipe video"
              allowFullScreen
            ></iframe>
          </div>
          <h2 className="font-alphabet text-center py-1 bg-white">
            Recipe Video
          </h2>
        </div>
      </>
    )
  }
}
