import React from "react"

export default function BasicBadge({ text, mobileFontSize }) {
  const size = mobileFontSize ? "text-" + mobileFontSize : ""
  return (
    <div className="inline-block">
      <span
        className={
          size +
          ` flex gap-4 justify-between rounded-full p-1 px-6 md:text-sm items-center text-omcBlack bg-white custom-shadow-md font-alphabet`
        }
      >
        {text}
      </span>
    </div>
  )
}
