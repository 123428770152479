import { Link } from "gatsby"
import React from "react"

export default function BlackButton({ text, link, isAlphabet, className }) {
  const cl =
    "flex gap-4 justify-center rounded-full md:py-3 py-2 px-8 items-center text-white bg-omcBlack custom-shadow-md hover:opacity-70 transition "

  const values = isAlphabet ? " font-alphabet " : ""

  return (
    <div className="inline-block">
      <Link to={link} className={cl + values + className}>
        {text}
      </Link>
    </div>
  )
}
