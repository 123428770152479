import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"

export default function GetRelatedSpecialFeature(specialFeatureName) {
  const allSpecialFeature = useStaticQuery(graphql`
    {
      allNewtSpecialFeature {
        edges {
          node {
            coverImage {
              src
            }
            description
            specialFeatureName
          }
        }
      }
    }
  `)

  const nodes = allSpecialFeature.allNewtSpecialFeature.edges.filter(data => {
    return (
      data.node.specialFeatureName === specialFeatureName.specialFeatureName
    )
  })

  const spfPost = nodes[0].node
  return (
    <div className="grid md:grid-cols-2 gap-4 place-items-center text-white p-8 pt-4">
      <Link
        to={`/special-feature/` + spfPost.specialFeatureName}
        className="block aspect-[2.2/1] overflow-hidden rounded-lg"
      >
        <ImageFromNewt src={spfPost !== "" ? spfPost.coverImage.src : ""} />
      </Link>
      <h3 className="leading-normal">
        {spfPost !== "" ? spfPost.specialFeatureName : ""}
      </h3>
    </div>
  )
}
