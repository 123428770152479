import React from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import ImageFromNewt from "../components/Atomic/ImageFromNewt"
import { StaticImage } from "gatsby-plugin-image"
import { Tab } from "@headlessui/react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import GrayBadge from "../components/Atomic/Badge/GrayBadge"
import BlackButton from "../components/Atomic/button/BlackButton"
import MarkerTitle from "../components/Atomic/Typography/MakerTitle"
import {
  TwitterShareButton,
  LineShareButton,
  PinterestShareButton,
} from "react-share"
import { FaCookieBite } from "react-icons/fa"

import VideoDisplay from "../components/Atomic/Post/VideoDisplay"
import ShowMustRead from "../components/Atomic/Post/ShowMustRead"
import RelatePost from "../components/Atomic/RelatedPost"

import Seo from "../components/Layout/Seo"
import JsonLd from "../components/Layout/json-ld"
import { Link } from "gatsby"

const twIcon = "../images/logo-twitter.png"
const lineIcon = "../images/line.png"
const pinterestIcon = "../images/pinterest.png"
const cookingIcon = "../images/cooking.png"

export default function RecipePost({ pageContext }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  let num = 0
  const {
    id,
    recipeTitle,
    eyecatch,
    ingredientsField,
    instruction,
    recipeMeta,
    youtubeLink,
    body,
    content_md,
    slug,
    categoryTags,
    relatedPostField,
  } = pageContext.post


  const contents = body !=null ? body.content : ""
  const contents_md = content_md !=null ? content_md : ""
  const shareUrl = `https://ohmycottie.com/recipes/` + slug

  function hasStepImageAndDisplay(data) {
    if (data !== null) {
      return (
        <div className="lg:col-span-2">
          <ImageFromNewt src={data.src} className="object-cover aspect-square" />
        </div>
      )
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  return (
    <div>
      <Layout>
        <Header />
        <div className="basic-wrapper pt-12">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel={recipeTitle}
          />
        </div>

        <article className="md:grid grid-cols-3 gap-8 basic-wrapper pb-16 pt-8 md:place-items-start">
          <div className="grid gap-4">
            <div className="rounded-xl overflow-hidden custom-shadow-md">
              <ImageFromNewt src={eyecatch.src} alt={recipeTitle} />
            </div>
            <h1 className="text-xl font-bold">{recipeTitle}</h1>
            <div className="flex gap-1">
              {categoryTags.tags.map(tag => {
                return <GrayBadge text={tag.tagName} />
              })}
            </div>
            <div className="flex gap-4">
              <span className="grid bg-white custom-shadow-md aspect-square rounded-full w-10">
                <TwitterShareButton url={shareUrl} title={recipeTitle}>
                  <StaticImage
                    src={twIcon}
                    layout="constrained"
                    alt="twitter"
                    width={24}
                    className={"m-auto"}
                  />
                </TwitterShareButton>
              </span>
              <span className="grid bg-white custom-shadow-md aspect-square rounded-full w-10">
                <LineShareButton title={recipeTitle} url={shareUrl}>
                  <StaticImage
                    src={lineIcon}
                    layout="constrained"
                    alt="twitter"
                    width={24}
                    className={"m-auto"}
                  />
                </LineShareButton>
              </span>
              <span className="grid bg-white custom-shadow-md aspect-square rounded-full w-10">
                <PinterestShareButton
                  media={eyecatch.src}
                  description={recipeTitle}
                  url={shareUrl}
                >
                  <StaticImage
                    src={pinterestIcon}
                    layout="constrained"
                    alt="twitter"
                    width={24}
                    className={"m-auto"}
                  />
                </PinterestShareButton>
              </span>
            </div>
            <div className="w-full overflow-hidden">
              <VideoDisplay video={youtubeLink} />
            </div>
            <div>
            <p className="text-xs pb-2">レシピで迷ったら…</p>
            <div className="border rounded-md p-4 text-center mb-8"><Link to="/about/standerd/" className="border-b">レシピのルールを見る</Link></div>
            </div>
            
          </div>

          <div className="md:border border-omcLightGray lg:rounded-[34px] col-span-2 md:px-10 md:py-16">
            <section className="entry-content">
            <div dangerouslySetInnerHTML={{ __html: contents}}></div>
            <div dangerouslySetInnerHTML={{ __html: contents_md}}></div>
            </section>
            

            <ShowMustRead relatedPostField={relatedPostField} />

            <section class="pt-12">
              <div className="flex gap-2 items-end justify-center mb-8">
                <MarkerTitle text={"How to cook..."} />
                <StaticImage
                  src={cookingIcon}
                  layout="constrained"
                  alt="cooking"
                  width={48}
                />
              </div>
              <div className="grid border rounded-md p-3">
                <p className="m-auto">
                  {recipeMeta.dishCount}
                  {recipeMeta.dishUnit}
                  {recipeMeta.remarks !== "" ? ` (${recipeMeta.remarks})` : ""}
                </p>
              </div>

              <div className="my-16">
                {/* tab label */}
                <Tab.Group>
                  <Tab.List className="flex rounded-full bg-[#E2E2E2] p-1 mb-8">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-full py-2.5 text-sm font-medium",
                          "focus:outline-none",
                          selected
                            ? "bg-[#313131] text-white shadow"
                            : "text-omcDark hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      材料
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-full py-2.5 text-sm font-medium",
                          "focus:outline-none",
                          selected
                            ? "bg-[#313131] text-white shadow"
                            : "text-omcDark hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      作り方
                    </Tab>
                  </Tab.List>

                  {/* tab content*/}
                  <Tab.Panels className="mt-2 entry">
                    <Tab.Panel>
                      <div id="ingredients">
                        {ingredientsField.map(d => {
                          const { data } = d
                          if (data.groupName === null) {
                            return (
                              <div className="px-2 border-b border-omcLightGray pt-1 mb-2 ">
                                <div className="flex justify-between gap-4">
                                  <p>{data.ingredients}</p>
                                  <p className=" w-[10ch] text-right">{data.quantity}</p>
                                </div>
                                <p className="text-xs text-omcBlueGray py-2">{data.comment !=null && data.comment}</p>
                              </div>
                            )
                          } else {
                            return (
                              <div className="flex items-center gap-1 pt-8 pb-2">
                                <FaCookieBite size={20} />
                                <p className="text-lg font-bold">
                                  {data.groupName}
                                </p>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div id="step" className="pt-6">
                        {instruction.map((d, key) => {
                          const { data } = d
                          if (data.groupName === null) {
                            num += 1
                            return (
                              <div
                                key={key}
                                className="flex flex-col lg:flex-row justify-between ml-4 px-2 py-4 border-b border-omcLightGray "
                              >
                                <span className="pr-2">{num}.</span>
                                <div className="grid lg:grid-cols-5 gap-2">
                                  <p className="lg:col-span-3">{data.step}</p>
                                  {hasStepImageAndDisplay(data.stepImage !== undefined ? data.stepImage : null)}
                                </div>

                              </div>
                            )
                          } else {
                            return (
                              <div className="flex items-center gap-1 pt-8 pb-2">
                                <FaCookieBite size={20} />
                                <p className="text-lg font-bold">
                                  {data.groupName}
                                </p>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </section>
          </div>
        </article>
        <div className="flex pb-12 justify-center">
          <BlackButton text="一覧へ戻る" link="/recipe/page/1" className="m-auto"/>
          </div>
        <RelatePost a_number={4} id={id} tags={categoryTags.tags} />
      </Layout>
    </div>
  )
}


export const Head = ({ pageContext }) => (
  <>
    <Seo
      title={pageContext.post.recipeTitle}
      description={pageContext.post.recipeMeta.recipeDescription}
      ogpImgPath={pageContext.post.eyecatch.src}
    />
     <script async="" type="text/javascript" src="https://assets.pinterest.com/js/pinit.js"></script>
  <script async src="https://platform.twitter.com/widgets.js"></script>
  <script async src="https://www.instagram.com/embed.js"></script>
  <script async src="//iframely.net/embed.js"></script>
  <JsonLd data={pageContext.post}/>
  </>
)