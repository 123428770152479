
import React from 'react';

const JsonLd = ({data}) => {
    const {
        recipeTitle,
        eyecatch,
        recipeMeta,
        ingredientsField,
        youtubeLink,
        instruction,
        releadedDate
    } = data
    const src = eyecatch.src
    const recipeDescription = recipeMeta.recipeDescription
    const dishCount  = recipeMeta.dishCount
    const dishUnit = recipeMeta.dishUnit
    const dishYield = [
      dishCount,
      dishCount+dishUnit
    ]
    const reciepeCategory = recipeMeta.metaCategory
    const recipeArea = recipeMeta.recipeArea

    const ingredients = ingredientsField.map((node)=>{
        const data = node.data
        if(data.groupName === null){
            const ing = data.ingredients + data.quantity
            return ing
        }else{
            return data.groupName
        }
    })

 
    
    const instructions = instruction.map(node=>{
      const data = node.data
      if(data.step != null){
        const ins = {
          "@type": "HowToStep",
          "text": data.step,
        }
        return ins
      }else{
        const g = {
          "@type": "HowToStep",
          "text": data.groupName,
        }
        return g
      }
    })
    const cook = recipeMeta.cookTime.replace(/[^0-9 ]/g, "")
    const prep = recipeMeta.prepTime.replace(/[^0-9 ]/g, "")
    const sum = parseInt(cook) + parseInt(prep)
    const time = sum > 59
        ? "PT" + Math.floor((sum / 60) * 10) / 10 + "H"
        : "PT" + sum + "M"




  

    const jsonLd = {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        "name": recipeTitle,
        "image": src,
        "author": {
          "@type": "Person",
          "name": "ohmycottie"
        },
        "description": recipeDescription,
        "prepTime":`PT${prep}M`,
        "cookTime": `PT${cook}M`,
        "totalTime": time,
        "recipeYield": dishYield,
        "recipeCategory": reciepeCategory,
        "recipeCuisine":recipeArea,
        "recipeIngredient": ingredients,
        "recipeInstructions": instructions,
        "video": {
          "@type": "VideoObject",
          "thumbnailUrl": src,
          "uploadDate":releadedDate,
          "name": recipeTitle,
          "description": recipeDescription,
          "contentUrl": youtubeLink,
        }
}
    return (
        <><script type="application/ld+json">{JSON.stringify(jsonLd)}</script></>
    );
}

export default JsonLd;
