import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderTitle from "./HeaderTitle"
import Card from "./Post/Card"

export default function RelatePost(props) {
  const data = useStaticQuery(graphql`
    query RelatedQuery {
      allNewtRecipe {
        edges {
          node {
            id
            categoryTags {
              tags {
                tagName
              }
            }
            eyecatch {
              src
            }
            slug
            recipeMeta {
              prepTime
              cookTime
              recipeName
            }
            youtubeLink
          }
        }
      }
    }
  `)

  const randomSelect = (array, num) => {
    let newArray = []
    while (newArray.length < num && array.length > 0) {
      const rand = Math.floor(Math.random() * array.length)
      newArray.push(array[rand])
      array.splice(rand, 1)
    }
    return newArray
  }
  const propsTagName = props.tags.map(tag => {
    return tag.tagName
  })

  const filteredPost = data.allNewtRecipe.edges.filter(data => {
    const tags = data.node.categoryTags.tags.map(t => {
      return t.tagName
    })
    const e = propsTagName.some(val => {
      return tags.toString().includes(val)
    })
    const filtered = e && data.node.id !== props.id
    return filtered
  })

  const relatedposts = randomSelect(filteredPost, props.a_number)
  if (!relatedposts.length) {
    return null
  }

  return (
    <div className="basic-wrapper py-16 bg-[#F9F9F9]">
      <HeaderTitle title={"Related Recipes"} className="font-alphabet text-center" />
      <div className="grid md:grid-cols-4 gap-4 pt-3">
        {relatedposts.map(edge => {
          const { recipeMeta, slug, eyecatch, youtubeLink, categoryTags } =
            edge.node
          const taglist = categoryTags.tags.map((tag, i) => {
            return tag.tagName
          })
          const cook = recipeMeta.cookTime.replace(/[^0-9 ]/g, "")
          const prep = recipeMeta.prepTime.replace(/[^0-9 ]/g, "")
          const sum = parseInt(cook) + parseInt(prep)
          const totalTime =
                sum > 59
                  ? Math.floor((sum / 60) * 10) / 10 + " hour"
                  : sum + " min."
          return (
            <Card
              title={recipeMeta.recipeName}
              img={eyecatch.src}
              tags={taglist}
              time={totalTime}
              link={slug}
              hasVideo={youtubeLink ? true : false}
            />
          )
        })}
      </div>
    </div>
  )
}
