import React from "react"

export default function SmallBadge({ text }) {
  return (
    <div className="inline-block">
      <span className="flex gap-4 justify-between rounded-full p-1 px-3 text-xs items-center text-white bg-omcOrange custom-shadow-md">
        {text}
      </span>
    </div>
  )
}
