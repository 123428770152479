import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"

export default function GetRelatedLifestyle({ title }) {
  const allLifestyle = useStaticQuery(graphql`
    {
      allNewtLifestyle {
        edges {
          node {
            eyecatch {
              src
            }
            title
          }
        }
      }
    }
  `)
  const nodes = allLifestyle.allNewtLifestyle.edges.filter(data => {
    return data.node.title === title
  })
  const post = nodes[0].node
  return (
    <div className="grid md:grid-cols-2 gap-4 place-items-center text-white p-8 pt-0">
      <Link
        to={`/life-style/` + post.title}
        className="block aspect-[2.2/1] overflow-hidden rounded-lg"
      >
        <ImageFromNewt src={post.eyecatch.src} />
      </Link>
      <h3 className="leading-normal">{post.title}</h3>
    </div>
  )
}
